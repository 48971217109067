import React from 'react';
import { navigate } from 'gatsby';
import { QueryParamProvider } from 'use-query-params';

const historyInterface = {
  push: ({ pathname, search, state }) => {
    navigate(`${pathname}${search}`, { state, replace: false });
  },
  replace: ({ pathname, search, state }) => {
    navigate(`${pathname}${search}`, { state, replace: true });
  },
};

export const wrapPageElement = ({ element, props: { location } }) => (
  <QueryParamProvider history={historyInterface} location={location}>
    {element}
  </QueryParamProvider>
);

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash || location.state?.disableScroll === true) {
    return false;
  }

  return true;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  const hasPrevLocation = prevLocation !== null;

  if (location.hash && (location.state?.disableScroll !== true || !hasPrevLocation)) {
    const element = document.getElementById(location.hash.substring(1));
    if (element !== null) {
      window.setTimeout(() => {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 250);
    }
  }
};
